<template>
  <div>
    <clock></clock>
    <detailTime></detailTime>
    <tabbar></tabbar>
  </div>
</template>
<script>
  import clock from "./clock.vue"
  import detailTime from './detailTime.vue'
  import tabbar from "@/components/tabbar.vue";
  export default {
    data() {
      return {

      };
    },
    components: {
      tabbar,
      clock,
      detailTime
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
<style scoped>
  .slide {
    position: relative;
    width: 95%;
    height: 500px;
    perspective: 1000px;
    perspective-origin: 50% 150%;
    transform-style: preserve-3d;
  }

  .slide .slide_item {
    transform-style: preserve-3d;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: absolute;
    z-index: 30;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* opacity: 0; */
    /* display: none; */
    text-align: center;
    font-size: 30px;
    line-height: 1;
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
    transition: 300ms;
    opacity: 0;
    flex-direction: column;
  }

  .slide .slide_item .title {
    font-size: 50px;
  }
</style>