<template>
    <div>
        <div style="background:#000;height:10rem;line-height:10rem;margin-bottom:1rem;">
            <div style="text-align:center;">
                <span style="color:#fff;font-size:2rem;font-weight: 700;padding-right:8px;">工具方法</span>
                <span style="color:#000;font-size:2rem;background:#f90;font-weight: 700;padding:0 8px;">集合</span>
            </div>
        </div>
        <van-grid :gutter="10" :column-num="3">
            <van-grid-item v-for="(item,index) in websitelist" :key="index" @click="gotoProject(item.url)"
                icon="photo-o" :text="item.title" />
        </van-grid>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                active: 0,
                websitelist: [{
                        title: "网易云听付费歌",
                        url: "https://note.youdao.com/s/Qp0E5kft"
                    },
                    {
                        title: "油猴插件使用",
                        url: "https://note.youdao.com/s/b3HinNk7"
                    }, {
                        title: "编辑歌词",
                        url: "https://note.youdao.com/s/5VpJQAqe"
                    }
                ]
            };
        },
        components: {

        },
        mounted() {

        },
        methods: {
            gotoProject(url) {
                window.open(url);
            }
        }
    }
</script>