import { render, staticRenderFns } from "./newWish.vue?vue&type=template&id=27b832f1&scoped=true&"
import script from "./newWish.vue?vue&type=script&lang=js&"
export * from "./newWish.vue?vue&type=script&lang=js&"
import style0 from "./newWish.vue?vue&type=style&index=0&id=27b832f1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b832f1",
  null
  
)

export default component.exports