<template>
    <div>
        <div class="hello">
            <van-row class="m-header">
                <van-col span="12">
                    <van-icon name="arrow-left" class="m-header-icon" @click="goback" />
                </van-col>
                <van-col span="12">
                    <div style="float:right;margin-right:8px;" @click="saveInfo">
                        保存
                    </div>
                </van-col>
            </van-row>
        </div>
        <div>
            <van-cell-group>
                <van-field v-model="form.name" label="姓名" placeholder="请输入名字" />
                <van-field v-model="form.mobile" type="tel" label="手机号" />
                <van-field readonly clickable name="picker" :value="form.sex" label="性别" placeholder="点击选择性别"
                    @click="showPickerSex = true" />

                <van-field readonly clickable name="datetimePicker" :value="form.birthday" label="出生日期"
                    placeholder="点击选择出生日期" @click="changeDatePicker" />
                <van-field v-model="form.sign" rows="3" autosize label="个人签名" type="textarea" maxlength="100"
                    placeholder="输入个性签名" show-word-limit />
                <van-popup v-model="showPickerSex" position="bottom">
                    <van-picker show-toolbar :columns="columns" @confirm="onConfirmSex"
                        @cancel="showPickerSex = false" />
                </van-popup>

                <van-popup v-model="showPicker" position="bottom">
                    <van-datetime-picker type="date" @confirm="onConfirm" @cancel="showPicker = false"
                        :min-date="minDate" :max-date="maxDate" v-model="currentDate" />
                </van-popup>
            </van-cell-group>
        </div>
    </div>
</template>
<script>
    import dayjs from "dayjs"
    import {
        updateuserinfo
    } from "../../api/api.js"
    export default {
        data() {
            var utc = require('dayjs/plugin/utc')
            dayjs.extend(utc)

            return {
                active: 0,
                value: 1,
                sex: "男",
                tel: "",
                showPicker: false,
                showPickerSex: false,
                columns: ['男', '女'],
                minDate: new Date(1960, 0, 1),
                maxDate: new Date(),
                currentDate: dayjs('1993-01-01'),
                form: {
                    birthday: "",
                    name: "",
                    age: "",
                    mobile: "",
                    sex: "",
                    sign: ""
                }
            };
        },
        components: {

        },
        mounted() {
            console.log("moundtedd")
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            console.log(userInfo)
            if (userInfo) {
                this.form = userInfo
                if (userInfo.sex == "0") {
                    this.form.sex = "男"
                } else {
                    this.form.sex = "女"
                }
                this.currentDate = dayjs(this.form.birthday).utc()
            } else {
                this.form = {
                    birthday: dayjs('1993-1-1').format("YYYY-MM-DD"),
                    name: "",
                    age: "",
                    mobile: "",
                    sex: "",
                }
                this.currentDate = dayjs('1993-1-1').utc()
            }
            console.log(this.currentDate)
        },
        methods: {
            changeDatePicker() {
                this.showPicker = true
            },

            goback() {
                this.$router.back(-1)
            },
            onConfirm(time) {
                this.form.birthday = dayjs(time).format("YYYY-MM-DD");
                this.showPicker = false;
            },
            onConfirmSex(sex) {
                this.form.sex = sex;
                this.showPickerSex = false;
            },
            async saveInfo() {
                console.log(this.form)
                let params = {
                    "age": this.form.age,
                    "birthday": this.form.birthday,
                    "mobile": this.form.mobile,
                    "name": this.form.name,
                    "sex": this.form.sex,
                    "sign": this.form.sign
                }
                if (this.form.sex == "男") {
                    params.sex = "0"
                    this.form.sex = "0"
                } else {
                    params.sex = "1"
                    this.form.sex = "1"
                }
                console.log('1111', params)
                const res = await updateuserinfo(params)
                if (res.code == 0) {
                    sessionStorage.setItem("userInfo", JSON.stringify(this.form))
                    console.log("更新成功了")
                    this.$router.back(-1)
                } else {
                    console.log("更新失败")
                }


            }
        }
    }
</script>
<style scoped>
    .m-header {
        height: 50px;
        line-height: 50px;
        background: #245fd7;
        color: #fff;
    }

    .m-header-icon {
        position: absolute;
        top: 16px;
        left: 6px;
        font-size: 18px;
    }
</style>