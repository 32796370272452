<template>
    <div>
        <div class="hello">
            <van-row class="m-header">
                <van-col span="12">
                    <van-icon name="arrow-left" class="m-header-icon" @click="goback" />
                </van-col>
                <van-col span="12">
                    <div style="float:right;margin-right:8px;" @click="saveInfo">
                        保存
                    </div>
                </van-col>
            </van-row>
        </div>
        <div>
            <div class="tip">

            </div>
            <van-cell-group>
                <van-field v-model="newyearwish" rows="4" autosize label="新年愿望" type="textarea" maxlength="200"
                    placeholder="请输入新年愿望" show-word-limit />
            </van-cell-group>
        </div>
    </div>
</template>
<script>
    import dayjs from "dayjs"
    import {
        wishcreate
    } from "../../api/api.js"
    export default {
        data() {
            var utc = require('dayjs/plugin/utc')
            dayjs.extend(utc)

            return {
                newyearwish: "",
                userInfo: JSON.parse(sessionStorage.getItem("userInfo"))
            };
        },
        components: {

        },
        mounted() {
            console.log("moundtedd")
            console.log(this.userInfo)
            // let newyearwish = sessionStorage.getItem('newyearwish')
            // if (newyearwish) {
            //     this.newyearwish = newyearwish
            // } else {
            //     this.newyearwish = ""
            // }
        },
        methods: {
            goback() {
                this.$router.back(-1)
            },
            async saveInfo() {
                let year = dayjs().format('YYYY')
                let createTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
                let params = {
                    mobile: this.userInfo.mobile,
                    wishdetail: this.newyearwish,
                    year,
                    create_time: createTime
                }
                const res = await wishcreate(params)
                if (res.code == 0) {
                    this.$toast.success(res.data);
                    this.$router.back(-1)
                } else {
                    this.$toast.fail(res.data);
                }
                // console.log(params)
                // sessionStorage.setItem("newyearwish", this.newyearwish)
                // console.log(wishcreate)
                //this.$router.back(-1)
            }
        }
    }
</script>
<style scoped>
    .m-header {
        height: 50px;
        line-height: 50px;
        background: #245fd7;
        color: #fff;
    }

    .m-header-icon {
        position: absolute;
        top: 16px;
        left: 6px;
        font-size: 18px;
    }

    .tip {
        font-family: shufa;
        width: 100%;
        height: 180px;
        background: url('../../assets/img/bg_title.jpg') no-repeat;
        background-size: 100% 100%;
    }
</style>