<template>
  <div>
    <div>
      <van-form @submit="onSubmit">
        <van-field v-model="mobile" name="用户名" label="用户名" placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]" />
        <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]" />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>

  </div>
</template>
<script>
  import axios from "axios";
  import http from '@/utils/httpRequest'
  import {
    login
  } from "../../api/api.js"
  import {
    remove
  } from '@/utils/util'
  export default {
    data() {
      return {
        mobile: "",
        password: "",
      };
    },
    methods: {
      getUrlData() {
        console.log(http)
      },
      onSubmit(values) {
        console.log("submit", values);
        let data = {
          mobile: this.mobile,
          password: this.password
        }
        console.log(data)
        login(data).then(res => {
          console.log(res)
          if (res.code == 0) {
            localStorage.setItem("mobile",data.mobile);
            sessionStorage.setItem('token', res.data.token)
            sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
            this.$router.push("main");
          } else {
            console.log(res.msg)
          }
        }).catch(error => {
          console.log(error)
        })
        //
      },
      wxlogin() {
        console.log("微信登陆");
        let url = "http://localhost:3000/login";
        axios.get(url).then((res) => {
          console.log(res);
          window.location.href = res.data;
        });
        // let data = ""
        // const login = http('/user', data, 'get');
        // console.log(login)
      },
      changeVuex() {
        this.$store.commit('increment')
        console.log(this.$store.state.count)
      },
      checkLogin(){
        if(localStorage.getItem("mobile")){
            this.mobile = localStorage.getItem("mobile");
            this.password = '123456';
            this.onSubmit()
        }else{
          this.mobile = '';
          this.password = '';
        }
      }
    },
    mounted() {
      this.checkLogin();
      let arr = [2, 3, 4]
      console.log(remove(arr, 2))
    }
  };
</script>
<style scoped>
  .bg_login {
    background-image: url('../../assets/img/login.png');
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
  }
</style>