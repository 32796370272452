import http from '../utils/httpRequest'


//H5 登录接口
export const login = data => http('/auth/jwt/login', data, 'post');

//初始化话
export const inituser = data => http('/api/user', data, 'post');

//更新个人信息接口
export const updateuserinfo = data => http('/api/updateuserinfo', data, 'post');

//创建愿望
export const wishcreate = data => http('/api/wish/wishcreate', data, 'post');

//获取个人愿望
export const getWishdetail = data => http('/api/wish/getdetail', data, 'post');

//获取所有人愿望
export const getAllWishdetail = data => http('/api/wish/getAlldetail', data, 'get');

//获取所有箴言
export const getAllproverb = data => http('/api/wish/getAllproverb', data, 'get');

//获取所有mainswpiper
export const swipermain = data => http('/api/swiper/swipermain', data, 'get');

//获取所有swiperwish
export const swiperwish = data => http('/api/swiper/swiperwish', data, 'get');