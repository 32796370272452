import Vue from "vue"
import VueRouter from "vue-router"
import Login from './views/login/login.vue'
import Main from './views/main/main.vue'
import Time from './views/time/time.vue'
import Wish from './views/wish/wish.vue'
import Person from './views/person/person.vue'
import PersonDetail from './views/person/persondetail.vue'
import newWish from './views/person/newWish.vue'
import initUser from './views/person/inituser.vue'
import mywish from './views/person/mywish.vue'
import allwish from './views/person/allwish.vue'
import Zhenyan from './views/zhenyan/zhenyan.vue'
import website from './views/website/website.vue'
import tools from './views/tools/tools.vue'
Vue.use(VueRouter)


// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
// 通过 Vue.extend() 创建的组件构造器，
// 或者，只是一个组件配置对象。
// 我们晚点再讨论嵌套路由。
const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/main',
    component: Main
  },
  {
    path: '/time',
    component: Time
  },
  {
    path: '/wish',
    component: Wish
  },
  {
    path: '/person',
    component: Person
  },
  {
    path: '/zhenyan',
    component: Zhenyan
  },
  {
    path: "/website",
    component: website
  },
  {
    path: "/tools",
    component: tools
  },
  {
    path: '/person/detail',
    component: PersonDetail
  },
  {
    path: '/person/newwish',
    component: newWish
  },
  {
    path: '/person/inituser',
    component: initUser
  },
  {
    path: '/person/mywish',
    component: mywish
  },
  {
    path: '/person/allwish',
    component: allwish
  }
]

const router = new VueRouter({
  routes
})
export default router