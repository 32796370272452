<template>
    <div>
        <p class="typewriter">{{typewriter}}</p>
    </div>
</template>
<script>
    export default {
        props: {
            str: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                typewriter: '',
                i: 0,
                timer: 0,

            };
        },
        components: {

        },
        mounted() {
            //this.typeing()
        },
        methods: {
            typeing(cb) {
                if (this.i <= this.str.length) {
                    this.typewriter = this.str.slice(0, this.i++)
                    this.timer = setTimeout(() => {
                        this.typeing(cb)
                    }, 80)
                } else {
                    clearTimeout(this.timer)
                    cb('结束了')
                }
            },
            start() {
                return new Promise((resolve) => {
                    this.typeing(resolve)
                })
            }
        }
    }
</script>
<style scoped>
    .typewriter {
        font-family: ruiziyun;
        font-size: 24px;
        letter-spacing: 4px;
    }
</style>