<template>
    <div>
        <div v-show="typewirte_show" style="padding:12px;">
            <typewriterCom ref="typewrite1" :str="str"></typewriterCom>
            <typewriterCom ref="typewrite2" :str="str1"></typewriterCom>
            <typewriterCom ref="typewrite3" :str="str2"></typewriterCom>
            <div style="text-align:center;">
                <van-button type="primary" style="padding: 0 30px;border-radius: 18px;border:none;border-radius:5%;background: #ff4d78;color:#fff;" v-show="showBtn" @click="checkWish">查看愿望</van-button>
            </div>

        </div>
        <div v-show="wish_card_show" style="padding-bottom:50px">
            <allwish></allwish>
        </div>
        <!-- <div class="containner_wish_card" v-show="wish_card_show">
            <div class="wish_card" v-for="(item,index) in listArr" :key="index"
                :style="[initCSS(index),Transform(index)]" @touchstart.stop.prevent.capture="touchStart($event,index)"
                @touchmove.stop.prevent.capture="touchMove($event,index)"
                @touchend.stop.prevent.capture="touchEnd(index)" v-show="item.show">
                <div class="wish_card_remark">
                    {{item.wish}}
                </div>
                <div class="wish_card_author">
                    <span>-</span> {{item.author}} <span>-</span>
                </div>
            </div>
            <div v-show="showReset">
                <van-button type="primary" @click="checkWish">重新查看</van-button>
            </div>
        </div> -->
        <tabbar></tabbar>
    </div>
</template>
<script>
    import typewriterCom from "@/components/typewriter.vue";
    import tabbar from "@/components/tabbar.vue";
    import allwish from "@/views/person/allwish.vue"
    export default {
        components: {
            tabbar,
            typewriterCom,
            allwish
        },
        data() {
            return {
                // str: "什么是人生赢家？ --'人生赢家'赢的不是结果，而是过程。不管你的起点高低，只要你做的事情让自己当下开心，将来回想起来也能微笑，就已经走在了人生赢家的路上。毕竟，衡量幸福的标准，不是你活过的天数，而是你愿意记住的日子。",
                // str1: "钱多钱少，开心到老。想清楚什么让自己得偿所愿，才是最应该做的事。",
                // str2: "所以，许愿望，实现愿望，就走在成功的路上了",
                // str: "人不是活一辈子，不是活几年，几月，几天，而是活几个瞬间。2022年是不平凡的一年，每个人都经历了不少的铭记时刻",
                // str1: "2023年来了，希望每个人都能有属于自己高光的时刻，实现许久未曾实现的愿望",
                // str2: "愿你拼尽全力不言后悔，迈向未来不留遗憾。",
                str: "我们最初工作赚钱，都是为了改善生活，但干着干着，我们却把挣钱当作了目标，而忘记了生活",
                str1: "2024年，愿你有好运，如果没有，希望你在慈悲中学会坚强。原有好多人爱你，如果没有，希望你在寂寞中学会宽容",
                str2: "希望大家身体健康，好好生活！！",
                showBtn: false,
                wish_card_show: false,
                typewirte_show: true,
                showReset: false,
                listArr: [],
                /*记录x y轴*/
                x: {
                    start: 0,
                    move: 0,
                    end: 0
                },
                y: {
                    start: 0,
                    move: 0,
                    end: 0
                },
                /*下标*/
                currentIndex: 0,
                /*宽度宽度*/
                winWidth: 0,
                /*xy位移*/
                displacement: {
                    x: 0,
                    y: 0
                },
                /*滑动*/
                swipering: false,
                /*滑动中*/
                slideing: false,
            };
        },
        mounted() {
            this.typeDetail();
        },
        methods: {
            checkWish() {
                this.typewirte_show = false
                this.wish_card_show = true;
                this.showReset = false;
                this.init()
            },
            async typeDetail() {
                const res1 = await this.$refs.typewrite1.start();
                const res2 = await this.$refs.typewrite2.start();
                const res3 = await this.$refs.typewrite3.start();
                console.log(res1, res2, res3)
                this.showBtn = true;
            },
            init() {
                /* 测试数据*/
                let arr = [{
                    wish: "活得清醒果然不是一件浪漫的事",
                    author: "Mr-Liu"
                }, {
                    wish: "钱多钱少，开心到老。想清楚什么让自己得偿所愿，才是最应该做的事。",
                    author: "Mr-Li"
                }, {
                    wish: "得偿所愿",
                    author: "Mr-Liu"
                }, {
                    wish: "从犹豫到相恋 先别让人发现",
                    author: "Mr-Liu"
                }]
                for (let index = 0; index < 4; index++) {
                    var data = {
                        index: index,
                        show: true,
                        x: 0,
                        y: 0,
                        opacity: index < 3 ? 1 : 0,
                        wish: arr[index].wish,
                        author: arr[index].author
                    };

                    this.listArr.push(data);
                }
                this.winWidth = document.documentElement.clientWidth;
            },
            initCSS(index) {
                let css = {};
                css["z-index"] = index;
                css["transform"] = `translate3d(0,0,${(this.currentIndex - index) * 50}px)`;
                css["transitionDuration"] = "0ms";

                return css;
            },
            Transform(index) {
                let css = {};
                if (!this.swipering) {
                    css["transitionDuration"] = "300ms";
                } else {
                    css["transitionDuration"] = "0ms";
                }
                if (index === this.currentIndex) {
                    css["transform"] =
                        `translate3d(${this.listArr[index].x}px,${this.listArr[index].y}px,${(this.currentIndex - index) * 50}px)`;
                }

                css['opacity'] = this.listArr[index].opacity;
                return css;
            },
            touchStart(e) {
                console.log("执行到了start")
                if (this.slideing) return;
                this.swipering = true;
                this.x.start = e.touches[0].pageX;
                this.y.start = e.touches[0].pageY;
            },
            // 滑动计算
            touchMove(e, index) {
                if (this.slideing) return;
                // if (this.listArr.length == index + 1) {
                //     return;
                // }
                this.x.move = e.touches[0].pageX;
                this.y.move = e.touches[0].pageY;

                this.displacement.x, (this.listArr[index].x = this.x.move - this.x.start);
                this.displacement.y, (this.listArr[index].y = this.y.move - this.y.start);

            },
            // 滑动结束判断是否超过一定值
            touchEnd(index) {
                this.swipering = false;
                // if (this.listArr.length == index + 1) {
                //     return;
                // }
                if (
                    this.listArr[index].x > 0 &&
                    this.listArr[index].x > this.winWidth / 2 - this.winWidth / 4.5
                ) {
                    this.touchEndNext(index);
                } else if (
                    this.listArr[index].x < 0 &&
                    this.listArr[index].x < -this.winWidth / 2 + this.winWidth / 4.5
                ) {
                    this.touchEndNext(index);
                } else {
                    this.listArr[index].x = 0;
                    this.listArr[index].y = 0;
                    this.slideing = false;
                }
                if (this.listArr.length == index + 1) {
                    this.showReset = true
                }
            },
            touchEndNext(index) {
                this.slideing = true;
                this.listArr[index].x = this.listArr[index].x * 3;
                this.listArr[index].y = this.listArr[index].y * 3;
                var a = setTimeout(() => {
                    this.$set(this.listArr[index], "show", false);
                    this.$nextTick(() => {
                        this.currentIndex++;
                        if (index + 3 < this.listArr.length) {
                            this.$set(this.listArr[index + 3], "opacity", 1);
                        }
                        this.slideing = false
                        a;
                    });
                }, 300);
            }
        }
    }
</script>
<style scoped>
    .typewriter {
        font-family: ruiziyun;
        font-size: 24px;
        letter-spacing: 4px;
    }

    .containner_wish_card {
        position: relative;
        height: 300px;
        perspective: 1000px;
        perspective-origin: 50% 150%;
        transform-style: preserve-3d;
    }

    .wish_card {
        position: absolute;
        left: 0;
        right: 0;
        /* display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; */
        transform-style: preserve-3d;
        font-family: ruiziyun;
        font-size: 24px;
        background: pink;
        transition: 300ms;
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) rotate(0deg);
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
        height: 300px;
        margin: 0 20px;
        border-radius: 20px;
        padding-top: 40px;
        padding-left: 24px;
        padding-right: 24px;
        opacity: 0;
    }

    .wish_card_remark {
        line-height: 34px;
        height: 220px;
    }

    .wish_card_author {
        font-size: 14px;
        text-align: center;
    }
</style>