<template>
    <div>
        <van-tabs v-model="active">
            <van-tab title="生">
                <div style="text-align:center;">
                    <div class="time_tip">
                        你的年龄
                    </div>
                    <div>
                        {{liveInfo.detail}}
                    </div>
                    <van-row>
                        <van-col span="8">
                            <div>
                                <div class="time_title">年</div>
                                <div class="time_value">{{liveInfo.year}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">月</div>
                                <div class="time_value">{{liveInfo.month}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">周</div>
                                <div class="time_value">{{liveInfo.week}}</div>
                            </div>
                        </van-col>
                    </van-row>
                    <van-row>
                        <van-col span="8">
                            <div>
                                <div class="time_title">日</div>
                                <div class="time_value">{{liveInfo.day}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">时</div>
                                <div class="time_value">{{liveInfo.hours}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">分</div>
                                <div class="time_value">{{liveInfo.minutes}}</div>
                            </div>
                        </van-col>
                    </van-row>
                </div>

            </van-tab>
            <van-tab title="dead">
                <div style="text-align:center;">
                    <div class="time_tip">
                        你的剩余时间
                        <van-icon name="question" @click="show = true" />
                    </div>
                    <div>
                        {{deadInfo.detail}}
                    </div>
                    <van-row>
                        <van-col span="8">
                            <div>
                                <div class="time_title">年</div>
                                <div class="time_value">{{deadInfo.year}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">月</div>
                                <div class="time_value">{{deadInfo.month}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">周</div>
                                <div class="time_value">{{deadInfo.week}}</div>
                            </div>
                        </van-col>
                    </van-row>
                    <van-row>
                        <van-col span="8">
                            <div>
                                <div class="time_title">日</div>
                                <div class="time_value">{{deadInfo.day}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">时</div>
                                <div class="time_value">{{deadInfo.hours}}</div>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <div class="time_title">分</div>
                                <div class="time_value">{{deadInfo.minutes}}</div>
                            </div>
                        </van-col>
                    </van-row>
                    <div class="leftlive_tip" style="margin-top:10px;">
                        你还能过{{deadInfo.year}}个春节
                    </div>
                    <div class="leftlive_tip">
                        你还能过{{deadInfo.week}}个周末
                    </div>
                    <div class="leftlive_tip">
                        所以要珍惜每一天的生活，珍惜眼前，让每一天过的精彩；
                    </div>
                </div>
            </van-tab>
            <van-dialog v-model="show" title="假设人生是一张白纸">
                <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
                <div style="font-size:12px;color:rgba(0,0,0,0.25);padding:2px;">一生80岁，每个格子代表一年，红色代表已经过去的，白色是剩余的时间</div>
                <div style="display:flex;flex-wrap:wrap">
                    <div v-for="(item,index) in 80" :class="item<=liveYear?'bg_red':''"
                        style="width:32px;height:24px;border:1px solid #ccc;" :key="index">

                    </div>
                </div>
            </van-dialog>
        </van-tabs>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                active: 0,
                show: false,
                birthday: '1993-09-18',
                liveYear: 0,
                liveInfo: {
                    detail: "0岁0天",
                    year: 0,
                    month: 0,
                    week: 0,
                    day: 0,
                    hours: 0,
                    minutes: 0
                },
                deadInfo: {
                    detail: "0岁0天",
                    year: 0,
                    month: 0,
                    week: 0,
                    day: 0,
                    hours: 0,
                    minutes: 0
                },
            };
        },
        components: {

        },
        mounted() {
            // console.log(this.computeliveday())
            let userinfo = JSON.parse(sessionStorage.getItem("userInfo"))
            console.log(userinfo)
            if (userinfo) {
                this.birthday = userinfo.birthday
                this.liveInfo = this.computeliveday();
                this.deadInfo = this.computedeadday();
                console.log(userinfo.birthday)
                console.log(this.birthday)
            }

        },
        methods: {
            getImage() {

            },
            computeliveday() {
                let birthday = this.birthday;
                let nowday = this.$dayjs().format('YYYY-MM-DD')
                console.log(birthday)
                let year = this.$dayjs(nowday).diff(this.$dayjs(birthday), 'year')
                let month = this.$dayjs(nowday).diff(this.$dayjs(birthday), 'month')
                let week = this.$dayjs(nowday).diff(this.$dayjs(birthday), 'week')
                let day = this.$dayjs(nowday).diff(this.$dayjs(birthday), 'day')
                let hours = this.$dayjs(nowday).diff(this.$dayjs(birthday), 'hour')
                let minutes = this.$dayjs(nowday).diff(this.$dayjs(birthday), 'minutes')
                let dayyear = parseInt(day / 365)
                let dayday = day % 365
                let detail = dayyear + '岁' + dayday + '天'
                this.liveYear = year;
                return {
                    detail,
                    year,
                    month,
                    week,
                    day,
                    hours,
                    minutes
                }
            },
            computedeadday() {
                let deadbirthday = this.$dayjs(this.birthday).add(80, 'year').format('YYYY-MM-DD')
                let nowday = this.$dayjs().format('YYYY-MM-DD')
                console.log(deadbirthday)
                let year = this.$dayjs(deadbirthday).diff(this.$dayjs(nowday), 'year')
                let month = this.$dayjs(deadbirthday).diff(this.$dayjs(nowday), 'month')
                let week = this.$dayjs(deadbirthday).diff(this.$dayjs(nowday), 'week')
                let day = this.$dayjs(deadbirthday).diff(this.$dayjs(nowday), 'day')
                let hours = this.$dayjs(deadbirthday).diff(this.$dayjs(nowday), 'hour')
                let minutes = this.$dayjs(deadbirthday).diff(this.$dayjs(nowday), 'minutes')
                let dayyear = parseInt(day / 365)
                let dayday = day % 365
                let detail = dayyear + '岁' + dayday + '天'
                return {
                    detail,
                    year,
                    month,
                    week,
                    day,
                    hours,
                    minutes
                }
            }
        }
    }
</script>
<style scoped>
    .time_tip {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.25);
        margin-bottom: 6px;
    }

    .time_title {
        margin-top: 4px;
    }

    .time_value {
        margin-top: 4px;
    }

    .bg_red {
        background: red;
    }

    .leftlive_tip {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.25)
    }
</style>