import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import Vant from 'vant';
import Vuex from 'vuex'
import dayjs from 'dayjs'
import 'vant/lib/index.css';
import "./assets/css/main.css"
import "./assets/css/icon.css"
Vue.use(Vant)
Vue.use(Vuex)

Vue.config.productionTip = false
const store = new Vuex.Store({
  state: {
    count: 0
  },
  mutations: {
    increment(state) {
      state.count++
    }
  }
})
store.commit('increment')
Vue.prototype.$dayjs = dayjs
console.log(store.state.count) // -> 1

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')