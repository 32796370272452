<template>
  <div>
    <!-- <van-tabbar route>
      <van-tabbar-item replace to="/main" icon="home-o">愿望</van-tabbar-item>
      <van-tabbar-item replace to="/time" icon="search">时间</van-tabbar-item>
      <van-tabbar-item replace to="/focus" icon="friends-o"
        >关键词</van-tabbar-item
      >
      <van-tabbar-item replace to="/person" icon="setting-o"
        >个人信息</van-tabbar-item
      >
    </van-tabbar> -->
    <content1></content1>
    <grid></grid>
    <div style="padding-bottom:50px;">
      <!-- <div id="demoCanvas" class="svga" ref="canvas"></div> -->
      <!-- <video width="100%" height="auto" autoplay="true" muted="true" loop="true" src="../../assets/img/tutu.mp4"></video> -->
      <img style="width: 100%;" src="../../assets/img/2024main.gif" alt="">
    </div>
    <!-- <van-button type="primary" @click="getImage">主要按钮</van-button>
    <div style="margin-bottom: 50px" id="img">
      <div v-for="item1 in items" :key="item1"
        style="line-height: 50px; height: 50px; text-align: center;background:#f00">
        item{{ item1 }}
      </div>
    </div> -->

    <tabbar></tabbar>
  </div>
</template>
<script>
  import tabbar from "@/components/tabbar.vue";
  import grid from "./grid.vue"
  import content1 from "./content.vue";
  import html2canvas from "html2canvas"
  // import SVGA from "svgaplayerweb";
  export default {
    data() {
      return {
        active: 0,
        svgaUrl: 'https://vr1.xiu123.cn/images/events/2023/tasksystem/normal.svga',
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      };
    },
    components: {
      tabbar,
      content1,
      grid
    },
    methods: {
      onLoad(player) {
        player.startAnimation()
      },
      getImage() {
        html2canvas(document.getElementById("img"), {
          scale: 4,
          logging: false,
          useCORS: true
        }).then(function (canvas) {
          // canvas为转换后的Canvas对象
          let oImg = new Image();
          oImg = canvas.toDataURL('image/png'); // 导出图片
          var oA = document.createElement("a");
          oA.download = ''; // 设置下载的文件名，默认是'下载'
          oA.href = oImg;
          document.body.appendChild(oA);
          oA.click();
          oA.remove(); // 下载之后把创建的元素删除
        })
      }
    },
    mounted() {
        // var player = new SVGA.Player("#demoCanvas");
        // var parser = new SVGA.Parser("#demoCanvas");
        // parser.load("https://vr1.xiu123.cn/images/events/2023/tasksystem/normal.svga", function(videoItem) {
        //     player.setVideoItem(videoItem);
        //     player.startAnimation();
        // });
    },
  }
</script>
<style>
.svga {
    width: 200px;
    height: 200px;
    margin: 100px auto;
}
</style>
