<template>
    <div style="width:100%;height:100%;">
        <div class="outer_container" id="picture">
            <div class="containner_wish_card" v-show="wish_card_show" style="padding-top:20px;">
                <div style="padding-left:24px;margin-bottom:16px;">
                    <span
                        style="font-size:32px;font-weight:blod;padding-right:8px;">{{day}}</span><span>/{{month}}</span>
                    <div style="line-height:44px;float:right;padding-right:8px;width:30px;">
                        <van-icon name="down" @click="getImage" />
                    </div>
                </div>
                <div class="wish_card" v-for="(item,index) in listArr" :key="index"
                    :style="[initCSS(index),Transform(index)]"
                    @touchstart.stop.prevent.capture="touchStart($event,index)"
                    @touchmove.stop.prevent.capture="touchMove($event,index)"
                    @touchend.stop.prevent.capture="touchEnd(index)" v-show="item.show">
                    <div class="wish_card_remark">
                        {{item.content}}
                    </div>
                    <div class="wish_card_author">
                        <span>-</span> {{item.author}} <span>-</span>
                    </div>
                </div>
                <div v-show="showReset" style="text-align:center;padding-top:50px;">
                    <van-button type="primary" @click="checkWish">再看一遍</van-button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import {
        getAllproverb
    } from "../../api/api.js"
    import html2canvas from "html2canvas"
    export default {
        components: {

        },
        data() {
            return {
                str: '当你老了，回顾一生，就会发觉：什么时候出国读书，什么时候决定做第一份职业、何时选定了对象而恋爱、什么时候结婚，其实都是命运的巨变。只是当时站在三岔路口，眼见风云千樯，还以为是生命中普通的一天。',
                str1: "道家有一句话：知其不可奈何。而安之若命。就是知道生命是这样的，但是我还是很勇敢和欣然地接受它面对它",
                showBtn: false,
                wish_card_show: true,
                typewirte_show: true,
                showReset: false,
                listArr: [],
                month: this.$dayjs().format('MM'),
                day: this.$dayjs().format('DD'),
                allproverb: [],
                /*记录x y轴*/
                x: {
                    start: 0,
                    move: 0,
                    end: 0
                },
                y: {
                    start: 0,
                    move: 0,
                    end: 0
                },
                /*下标*/
                currentIndex: 0,
                /*宽度宽度*/
                winWidth: 0,
                /*xy位移*/
                displacement: {
                    x: 0,
                    y: 0
                },
                /*滑动*/
                swipering: false,
                /*滑动中*/
                slideing: false,
            };
        },
        mounted() {
            this.getAllproverb()
        },
        methods: {
            getImage() {
                html2canvas(document.getElementById("picture"), {
                    scale: 4,
                    logging: false,
                    useCORS: true
                }).then(function (canvas) {
                    // canvas为转换后的Canvas对象
                    let oImg = new Image();
                    oImg = canvas.toDataURL('image/png'); // 导出图片
                    var oA = document.createElement("a");
                    oA.download = ''; // 设置下载的文件名，默认是'下载'
                    oA.href = oImg;
                    document.body.appendChild(oA);
                    oA.click();
                    oA.remove(); // 下载之后把创建的元素删除
                })
            },
            async getAllproverb() {
                const res = await getAllproverb()
                if (res.code == 0) {
                    this.allproverb = res.data
                    this.init(this.allproverb)
                } else {
                    console.log(res)
                }
            },
            checkWish() {
                this.typewirte_show = false
                this.wish_card_show = true;
                this.showReset = false;
                this.init(this.allproverb)
            },
            async typeDetail() {
                const res1 = await this.$refs.typewrite1.start();
                const res2 = await this.$refs.typewrite2.start();
                console.log(res1, res2)
                this.showBtn = true;
            },
            init(arr) {
                /* 测试数据*/
                // let arr = [{
                //     content: "活得清醒果然不是一件浪漫的事",
                //     author: "Mr-Liu"
                // }, {
                //     content: "钱多钱少，开心到老。想清楚什么让自己得偿所愿，才是最应该做的事。",
                //     author: "Mr-Li"
                // }, {
                //     content: "得偿所愿",
                //     author: "Mr-Liu"
                // }, {
                //     content: "从犹豫到相恋 先别让人发现",
                //     author: "Mr-Liu"
                // }, {
                //     content: "不管你曾经被伤害得有多深，总会有一个人的出现，让你原谅之前生活对你所有的刁难。",
                //     author: "宫崎骏《幽灵公主》"
                // }]
                for (let index = 0; index < arr.length; index++) {
                    var data = {
                        index: index,
                        show: true,
                        x: 0,
                        y: 0,
                        opacity: index < 3 ? 1 : 0,
                        content: arr[index].content,
                        author: arr[index].author
                    };

                    this.listArr.push(data);
                }
                this.winWidth = document.documentElement.clientWidth;
            },
            initCSS(index) {
                let css = {};
                css["z-index"] = index;
                css["transform"] = `translate3d(0,0,${(this.currentIndex - index) * 50}px)`;
                css["transitionDuration"] = "0ms";

                return css;
            },
            Transform(index) {
                let css = {};
                if (!this.swipering) {
                    css["transitionDuration"] = "300ms";
                } else {
                    css["transitionDuration"] = "0ms";
                }
                if (index === this.currentIndex) {
                    css["transform"] =
                        `translate3d(${this.listArr[index].x}px,${this.listArr[index].y}px,${(this.currentIndex - index) * 50}px)`;
                }

                css['opacity'] = this.listArr[index].opacity;
                return css;
            },
            touchStart(e) {
                console.log("执行到了start")
                if (this.slideing) return;
                this.swipering = true;
                this.x.start = e.touches[0].pageX;
                this.y.start = e.touches[0].pageY;
            },
            // 滑动计算
            touchMove(e, index) {
                if (this.slideing) return;
                // if (this.listArr.length == index + 1) {
                //     return;
                // }
                this.x.move = e.touches[0].pageX;
                this.y.move = e.touches[0].pageY;

                this.displacement.x, (this.listArr[index].x = this.x.move - this.x.start);
                this.displacement.y, (this.listArr[index].y = this.y.move - this.y.start);

            },
            // 滑动结束判断是否超过一定值
            touchEnd(index) {
                this.swipering = false;
                // if (this.listArr.length == index + 1) {
                //     return;
                // }
                if (
                    this.listArr[index].x > 0 &&
                    this.listArr[index].x > this.winWidth / 2 - this.winWidth / 4.5
                ) {
                    this.touchEndNext(index);
                } else if (
                    this.listArr[index].x < 0 &&
                    this.listArr[index].x < -this.winWidth / 2 + this.winWidth / 4.5
                ) {
                    this.touchEndNext(index);
                } else {
                    this.listArr[index].x = 0;
                    this.listArr[index].y = 0;
                    this.slideing = false;
                }
                if (this.listArr.length == index + 1) {
                    this.showReset = true
                }
            },
            touchEndNext(index) {
                this.slideing = true;
                this.listArr[index].x = this.listArr[index].x * 3;
                this.listArr[index].y = this.listArr[index].y * 3;
                var a = setTimeout(() => {
                    this.$set(this.listArr[index], "show", false);
                    this.$nextTick(() => {
                        this.currentIndex++;
                        if (index + 3 < this.listArr.length) {
                            this.$set(this.listArr[index + 3], "opacity", 1);
                        }
                        this.slideing = false
                        a;
                    });
                }, 300);
            }
        }
    }
</script>
<style scoped>
    .outer_container {
        width: 100%;
        height: 100vh;
        background: beige;
    }

    .typewriter {
        font-family: ruiziyun;
        font-size: 24px;
        letter-spacing: 4px;
    }

    .containner_wish_card {
        position: relative;
        height: 300px;
        perspective: 1000px;
        perspective-origin: 50% 150%;
        transform-style: preserve-3d;
    }

    .wish_card {
        position: absolute;
        left: 0;
        right: 0;
        /* display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; */
        transform-style: preserve-3d;
        font-family: ruiziyun;
        font-size: 24px;
        background: pink;
        transition: 300ms;
        opacity: 1;
        transform: translate3d(0px, 0px, 0px) rotate(0deg);
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
        height: 300px;
        margin: 0 20px;
        border-radius: 20px;
        padding-top: 40px;
        padding-left: 24px;
        padding-right: 24px;
        opacity: 0;
    }

    .wish_card_remark {
        line-height: 34px;
        height: 220px;
    }

    .wish_card_author {
        font-size: 14px;
        text-align: center;
    }
</style>