<template>
  <div>
    <div style="height: 200px">
      <van-swipe :autoplay="3000" style="height: 100%; width: 100%">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img style="width: 100%" v-lazy="image.url" />
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
  import Vue from "vue";
  import {
    Lazyload
  } from "vant";
  import {
    swipermain
  } from "../../api/api.js"
  Vue.use(Lazyload);

  export default {
    data() {
      return {
        images: [
          "https://img01.yzcdn.cn/vant/apple-1.jpg",
          "https://img01.yzcdn.cn/vant/apple-2.jpg",
        ],
      };
    },
    mounted() {
      console.log("CCCCC")
      this.swipermain()
    },
    methods: {
      async swipermain() {
        const res = await swipermain()
        if (res.code == 0) {
          this.images = res.data
        }
      }
    }
  };
</script>