<template>
    <div>
        <div style="text-align:center;font-size:24px;padding-top:1rem;font-weight:blod;">
            WELCOME TO YOUR WORLD
        </div>
        <div class="container_clock">
            <div style="width:300px;">
                <canvas id="clock" width="300" height="300"></canvas>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                active: 0,
            };
        },
        components: {

        },
        mounted() {
            this.drawClock();
            //this.drawClock();
            // 每40ms执行一次drawClock()，人眼看来就是流畅的画面
            //this.interval = 
            //setInterval(this.drawClock, 40);
        },
        methods: {
            getImage() {

            },
            drawClock() {
                const ctx = document.getElementById('clock').getContext("2d");

                var height = ctx.canvas.width;
                var width = ctx.canvas.height;
                ctx.clearRect(0, 0, width, height)

                // 设置文字对应的半径
                var R = width / 2 - 60;
                console.log(height, width, R, width / 2, height / 2)
                // 把原点的位置移动到屏幕中间，及宽的一半，高的一半
                ctx.translate(width / 2, height / 2);
                //ctx.translate(width / 2, height / 2)
                // 画外框
                function drawBackground() {
                    // 设置线条的粗细，单位px
                    ctx.lineWidth = 10;
                    // 开始路径
                    ctx.beginPath();
                    // 运动一个圆的路径
                    // arc(x,y,半径,起始位置，结束位置，false为顺时针运动)
                    ctx.arc(0, 0, width / 2 - 30, 0, 2 * Math.PI, false);
                    ctx.closePath();
                    // 描出点的路径
                    ctx.stroke();
                }
                // // 画时钟数
                function drawHoursNum() {
                    ctx.setFontSize = 20;
                    // 圆的起始位置是从3开始的，所以我们从3开始填充数字
                    var hours = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2];
                    hours.forEach(function (hour, i) {
                        var rad = (2 * Math.PI / 12) * i;
                        var x = R * Math.cos(rad);
                        var y = R * Math.sin(rad);
                        // 因为微信小程序不支持BaseLine这个属性，所以这里我们只能自己手动调整位置
                        if (hour == 12) {
                            ctx.fillText(hour, x - 11, y + 6);
                        } else if (hour == 6) {
                            ctx.fillText(hour, x - 5, y + 6);
                        } else {
                            ctx.fillText(hour, x - 6, y + 6);
                        }
                    })
                }
                // // 画数字对应的点
                function drawdots() {
                    for (let i = 0; i < 60; i++) {
                        var rad = 2 * Math.PI / 60 * i;
                        var x = (R + 15) * Math.cos(rad);
                        var y = (R + 15) * Math.sin(rad);
                        ctx.beginPath();
                        // 每5个点一个比较大
                        if (i % 5 == 0) {
                            ctx.arc(x, y, 2, 0, 2 * Math.PI, false);
                        } else {
                            ctx.arc(x, y, 1, 0, 2 * Math.PI, false);
                        }
                        ctx.fillStyle = 'black';
                        ctx.fill();
                    }
                    ctx.closePath();
                }

                // // 画时针
                function drawHour(hour, minute) {
                    // 保存画之前的状态
                    ctx.save();
                    ctx.beginPath();
                    // 根据小时数确定大的偏移
                    var rad = 2 * Math.PI / 12 * hour;
                    // 根据分钟数确定小的偏移
                    var mrad = 2 * Math.PI / 12 / 60 * minute;
                    // 做旋转
                    ctx.rotate(rad + mrad);
                    ctx.lineWidth = 8;
                    // 设置线条结束样式为圆
                    ctx.lineCap = 'round';
                    // 时针向后延伸8个px；
                    ctx.moveTo(0, 8);
                    // 一开始的位置指向12点的方向，长度为R/2
                    ctx.lineTo(0, -R / 2);
                    ctx.stroke();
                    ctx.closePath();
                    // 返回画之前的状态
                    ctx.restore();
                }

                // // 画分针
                function drawMinute(minute, second) {
                    ctx.save();
                    ctx.beginPath();
                    // 根据分钟数确定大的偏移
                    var rad = 2 * Math.PI / 60 * minute;
                    // 根据秒数确定小的偏移
                    var mrad = 2 * Math.PI / 60 / 60 * second;
                    ctx.rotate(rad + mrad);
                    // 分针比时针细
                    ctx.lineWidth = 6;
                    ctx.lineCap = 'round';
                    ctx.moveTo(0, 10);
                    // 一开始的位置指向12点的方向，长度为3 * R / 4
                    ctx.lineTo(0, -3 * R / 4);
                    ctx.stroke();
                    ctx.closePath();
                    ctx.restore();
                }

                // // 画秒针
                function drawSecond(second, msecond) {
                    ctx.save();
                    ctx.beginPath();
                    // 根据秒数确定大的偏移
                    var rad = 2 * Math.PI / 60 * second;
                    // 1000ms=1s所以这里多除个1000
                    var mrad = 2 * Math.PI / 60 / 1000 * msecond;
                    ctx.rotate(rad + mrad);
                    ctx.lineWidth = 4;
                    // 设置线条颜色为红色，默认为黑色
                    ctx.strokeStyle = 'red';
                    ctx.lineCap = 'round';
                    ctx.moveTo(0, 12);
                    ctx.lineTo(0, -R);
                    ctx.stroke();
                    ctx.closePath();
                    ctx.restore();
                }

                // //画出中间那个灰色的圆
                function drawDot() {
                    ctx.beginPath();
                    ctx.arc(0, 0, 8, 0, 2 * Math.PI, false);
                    ctx.fillStyle = 'lightgrey';
                    ctx.fill();
                    ctx.closePath();
                }

                function Clock() {
                     ctx.clearRect(0, 0, width, height)
                    // 实时获取各个参数
                    var now = new Date();
                    var hour = now.getHours();
                    var minute = now.getMinutes()
                    var second = now.getSeconds();
                    var msecond = now.getMilliseconds();
                    // 依次执行各个方法
                    drawBackground();
                    drawHoursNum();
                    drawdots();
                    drawHour(hour, minute);
                    drawMinute(minute, second);
                    drawSecond(second, msecond);
                    drawDot();
                    // 微信小程序要多个draw才会画出来，所以在最后画出
                    // ctx.restore();
                    //ctx.draw();
                    //ctx.closePath();
                }
                // 执行Clock这个方法，实际上执行了所有步骤
                Clock()
                // setInterval(, 1000);
                // setInterval(() => {
                //     Clock()
                // }, 1000);
            }

        }
    }
</script>
<style scoped>
    .container_clock {
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .canvas_clock {
        height: 100%;
        width: 100%;
    }
</style>