import axios from 'axios'
import router from '@/router'
// url
//axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.baseURL = "https://www.caesarempire.top";
//axios.defaults.baseURL = "http://localhost:7001";
const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept-Language': 'zh-CN'
  }
});

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers['token'] = sessionStorage.getItem('token') // 请求头带上token
  config.headers['Accept-Language'] = 'zh-CN' // 请求头带上token
  return config;
}, error => {
  return Promise.reject(error);
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data && response.data.code === 401) { // 401, token失效
    sessionStorage.clear();
    router.push({
      name: 'Login'
    })
  }
  return response.data;
}, error => {
  return Promise.reject(error)
});


/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  if (/^http/.test(actionName)) {
    return actionName;
  } else {
    return axios.defaults.baseURL + actionName
  }

}



/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */

var getData = function (url, data, method = 'get', restful) {

  if (restful) {
    url = url + '/' + restful;
  }
  var obj = {
    url: http.adornUrl(url),
    method: method
  }
  if (method.toLowerCase() == 'get') {
    //  data.t = new Date().getTime();
    obj.params = data;
  } else if (method.toLowerCase() == 'delete') {
    obj.data = data;
  } else {
    obj.data = data;
  }
  return http(obj)
}

export default getData