<template>
    <div>
        <div style="display:flex;height:100%;">
            <div style="width:80px;height:100vh;overflow:scroll;">
                <van-sidebar v-model="activeKey" @change="onChange">
                    <van-sidebar-item :title="item.name" v-for="(item,index) in list" :key="index" />
                </van-sidebar>
            </div>
            <div style="flex:1;height:100vh;overflow:scroll;">
                <div style="display:flex;margin-top:10px;border-bottom:1px solid #ccc;line-height:34px;">
                    <div style="display:flex;justify-content: center;
        align-items: center;margin-left:10px;">
                        <div style="width:10px;height:10px;background:#1cbbb4;border-radius:50%;"></div>
                    </div>
                    <div style="padding-left:12px;">
                        <span>{{currentItem.name}}</span>
                    </div>

                </div>

                <div v-for="(item,index) in currentItem.children" :key="'outter'+index" class="cu-timeline">
                    <div class="cu-time">
                        {{item.year}}
                    </div>
                    <div class="cu-item">
                        <div class="content">
                            <div v-for="(wishItem,index) in item.children" :key="'inner'+index">
                                <div class="bg-cyan" style="width:40px;font-size:12px;text-align:center;">
                                    {{wishItem.createtime}}
                                </div>
                                <div style="padding:8px;">
                                    {{wishItem.wishdetail}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- {{wishItem.create_time}}-{{wishItem.wishdetail}}- -->
                </div>
                <!--<div v-for="(item,index) in list" :key="'d'+index">
                    <div style="height:59px;">
                        姓名：{{item.name}}
                    </div>
                     

                </div>-->
            </div>
        </div>

    </div>
</template>
<script>
    import dayjs from "dayjs"
    import {
        getAllWishdetail
    } from "../../api/api.js"
    export default {
        data() {
            var utc = require('dayjs/plugin/utc')
            dayjs.extend(utc)
            return {
                activeKey: 0,
                list: [],
                currentItem: {}
            };
        },
        components: {

        },
        mounted() {
            this.initlist()
        },
        methods: {
            async initlist() {
                // let list = [{}];
                // for (let i = 0; i < 26; i++) {
                //     list[i] = {};
                //     //list[i].name = String.fromCharCode(65 + i);
                //     list[i].name = '刘大' + i;
                //     list[i].id = i;
                // }
                // this.list = list
                const res = await getAllWishdetail()
                if (res.code == 0) {
                    this.list = this.handledata(res.data)
                    if (this.list.length > 0) {
                        this.currentItem = this.list[0]
                    }
                    console.log('cccc', this.list)
                } else {
                    console.log("请求出错了")
                }
            },
            handledata(arr) {
                let obj = {}
                arr.forEach(item => {
                    if (item.mobile) {
                        if (obj[item.mobile]) {
                            let miniobj = {
                                create_time: item.create_time,
                                year: item.year,
                                wishdetail: item.wishdetail
                            }
                            obj[item.mobile].children.push(miniobj)
                        } else {
                            obj[item.mobile] = {
                                name: item.name,
                                avatarUrl: item.avatarUrl,
                                children: [{
                                    create_time: item.create_time,
                                    year: item.year,
                                    wishdetail: item.wishdetail
                                }]
                            }
                        }
                    }
                })
                for (let key1 in obj) {
                    if (obj[key1].children && obj[key1].children.length > 0) {
                        let arrchild = JSON.parse(JSON.stringify(obj[key1].children));
                        let objchild = {}
                        arrchild.forEach(item1 => {
                            if (objchild[item1.year]) {
                                let obj1 = {
                                    createtime: dayjs(item1.create_time).format('MM-DD'),
                                    wishdetail: item1.wishdetail
                                }
                                objchild[item1.year].children.push(obj1)
                            } else {
                                objchild[item1.year] = {
                                    year: item1.year,
                                    children: [{
                                        createtime: dayjs(item1.create_time).format('MM-DD'),
                                        wishdetail: item1.wishdetail
                                    }]
                                }
                            }
                        })
                        let ddarr = []
                        for (let ddkey in objchild) {
                            ddarr.push(objchild[ddkey])
                        }
                        obj[key1].children = ddarr
                    }

                }
                let newArr = []
                for (let key in obj) {
                    newArr.push(obj[key])
                }
                return newArr

            },
            onChange(index) {
                console.log(index)
                this.currentItem = this.list[index]
                console.log(this.currentItem)
                // this.$notify({
                //     type: 'primary',
                //     message: index
                // });
            },
        }
    }
</script>
<style scoped>
    .cu-timeline {
        display: block;
        background-color: #fff;

    }

    .cu-timeline .cu-time {
        width: 60px;
        text-align: center;
        padding: 10px 0;
        font-size: 13px;
        color: #888;
        display: block;
    }

    .cu-timeline>.cu-item:not([class*="text-"]) {
        color: #ccc;
    }

    .cu-timeline>.cu-item {
        padding: 15px 15px 15px 60px;
        position: relative;
        display: block;
        z-index: 0;
    }

    .cu-timeline>.cu-item:not([class*="cuIcon-"])::before {
        content: "\e763"
    }

    .cu-timeline>.cu-item::before {
        font-family: cuIcon;
        display: block;
        position: absolute;
        top: 18px;
        z-index: 9;
        background-color: #fff;
        width: 25px;
        height: 25px;
        text-align: center;
        border: none;
        line-height: 25px;
        left: 18px;
    }

    .cu-timeline>.cu-item::after {
        content: "";
        display: block;
        position: absolute;
        width: 0.5px;
        background-color: #ddd;
        left: 30px;
        height: 100%;
        top: 0;
        z-index: 8;
    }

    .cu-timeline>.cu-item>.content:not([class*="bg-"]) {
        background-color: #f1f1f1;
        color: #333;
    }

    .cu-timeline>.cu-item>.content {
        padding: 15px;
        border-radius: 3px;
        display: block;
        line-height: 1.6;
    }

    .bg-cyan {
        background-color: #1cbbb4;
        color: #fff;
    }

    .van-sidebar-item--select::before {
        background-color: #1cbbb4 !important;
    }
</style>