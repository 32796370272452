<template>
    <div>
        <div class="hello">
            <van-row class="m-header">
                <van-col span="12">
                    <van-icon name="arrow-left" class="m-header-icon" @click="goback" />
                </van-col>
                <van-col span="12">
                    <div style="float:right;margin-right:8px;" @click="saveInfo">
                        初始化
                    </div>
                </van-col>
            </van-row>
        </div>
        <div>
            <van-cell-group>
                <van-field v-model="form.name" label="姓名" placeholder="请输入名字" />
                <van-field v-model="form.mobile" type="tel" label="手机号" />
            </van-cell-group>
        </div>
    </div>
</template>
<script>
    import dayjs from "dayjs"
    import {
        inituser
    } from "../../api/api.js"
    export default {
        data() {
            var utc = require('dayjs/plugin/utc')
            dayjs.extend(utc)

            return {
                active: 0,
                value: 1,
                sex: "男",
                tel: "",
                showPicker: false,
                showPickerSex: false,
                columns: ['男', '女'],
                minDate: new Date(1960, 0, 1),
                maxDate: new Date(),
                currentDate: dayjs('1993-01-01'),
                form: {
                    name: "",
                    mobile: "",
                }
            };
        },
        components: {

        },
        mounted() {
            console.log("moundtedd")

        },
        methods: {
            changeDatePicker() {
                this.showPicker = true
            },

            goback() {
                this.$router.back(-1)
            },
            onConfirm(time) {
                this.form.birthday = dayjs(time).format("YYYY-MM-DD");
                this.showPicker = false;
            },
            onConfirmSex(sex) {
                this.form.sex = sex;
                this.showPickerSex = false;
            },
            async saveInfo() {
                console.log(this.form)
                let params = {
                    "mobile": this.form.mobile,
                    "name": this.form.name,
                }
                console.log('1111', params)
                const res = await inituser(params)
                if (res.code == 0) {
                    // sessionStorage.setItem("userInfo", JSON.stringify(this.form))
                    console.log("初始化成功了")
                    this.$router.back(-1)
                } else {
                    console.log("初始化失败")
                }


            }
        }
    }
</script>
<style scoped>
    .m-header {
        height: 50px;
        line-height: 50px;
        background: #245fd7;
        color: #fff;
    }

    .m-header-icon {
        position: absolute;
        top: 16px;
        left: 6px;
        font-size: 18px;
    }
</style>