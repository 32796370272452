<template>
    <div>
        <div class="person_header">
            <div>
                <van-image width="4rem" height="4rem" round fit="cover" :src="url" />
            </div>
            <div>
                {{name}}
            </div>
            <div>
                {{sign}}
            </div>
        </div>
        <div>
            <van-cell title="个人信息" is-link to="person/detail" />
            <van-cell title="新年愿望" is-link to="person/newWish" />
            <!-- <van-cell title="我的愿望" is-link to="person/mywish" /> -->
            <van-cell v-if="showInit" title="初始化用户" is-link to="person/inituser" />
            <van-cell title="全部愿望" is-link to="person/allwish" />
            <van-cell title="退出登录" is-link to="login" />
        </div>

        <tabbar></tabbar>
    </div>
</template>
<script>
    import tabbar from "@/components/tabbar.vue";
    export default {
        data() {
            return {
                name: "",
                sign: "",
                url: "",
                showInit: false
            };
        },
        components: {
            tabbar,
        },
        mounted() {
            let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            console.log(userInfo)
            if (userInfo) {
                this.name = userInfo.name
                this.sign = userInfo.sign
                if (userInfo.avatarUrl) {
                    this.url = userInfo.avatarUrl
                } else {
                    this.url = "https://img01.yzcdn.cn/vant/cat.jpeg"
                }

                if (userInfo.mobile == "13231616255") {
                    this.showInit = true
                } else {
                    this.showInit = false
                }
            } else {
                this.showInit = false
                this.name = ""
                this.sign = ""
                this.url = "https://img01.yzcdn.cn/vant/cat.jpeg"
            }
        },
        methods: {

        }
    }
</script>
<style scoped>
    .person_header {
        padding-top: 50px;
        padding-left: 20px;
        height: 200px;
        background: #abcded;
    }
</style>