<template>
    <div>
        <div style="background:#000;height:10rem;line-height:10rem;margin-bottom:1rem;">
            <div style="text-align:center;">
                <span style="color:#fff;font-size:2rem;font-weight: 700;padding-right:8px;">实用网站</span>
                <span style="color:#000;font-size:2rem;background:#f90;font-weight: 700;padding:0 8px;">集合</span>
            </div>
        </div>
        <van-grid :gutter="10" :column-num="3">
            <van-grid-item v-for="(item,index) in websitelist" :key="index" @click="gotoProject(item.url)"
                icon="photo-o" :text="item.title" />
        </van-grid>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                active: 0,
                websitelist: [{
                        title: "吾爱破解软件",
                        url: "https://www.52pojie.cn/"
                    },
                    {
                        title: "视频会员解析",
                        url: "https://www.playm3u8.cn/jiexi.php?url="
                    },
                    {
                        title: "百度网盘会员",
                        url: "https://www.baiduyun.wiki/zh-cn/vip.html"
                    },
                    {
                        title: "付费歌下载",
                        url: "https://www.yijingying.com/html/musictools/"
                    },
                    {
                        title: "合成大西瓜",
                        url: "http://g.dashenti.com/game/348/"
                    },
                    {
                        title: "哥俩好",
                        url: "http://www.wesane.com/game/351/"
                    }
                ]
            };
        },
        components: {

        },
        mounted() {

        },
        methods: {
            gotoProject(url) {
                window.open(url);
            }
        }
    }
</script>