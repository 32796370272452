export const once = function (fn) {
    let callee = false
    return function () {
        if (!callee) {
            callee = true
            fn.apply(this, arguments)
        }
    }
}

export const remove = function (arr, item) {
    if (arr.length > 0) {
        let index = arr.indexOf(item)
        if (index > -1) {
            return arr.splice(index, 1)
        }
    }
}